`<template>
  <section class="documents"></section>
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
const envVariables = process.env;

const { VUE_APP_HELP_AND_DOCUMENTS_APP_URL } = envVariables;

export default {
  name: "HelpAndDocuments",
  created() {
    const subdomain = getSubdomain();
    const baseUrl = subdomain
      ? apiEndpoints.company.temptoken
      : `${apiEndpoints.master.temptoken}/all`;
    httpServiceAuth
      .post(baseUrl)
      .then(response => {
        const { access_token } = response.data;

        let subdomainUrl = "";
        if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;
        if (access_token) {
          window.location.replace(
            `${VUE_APP_HELP_AND_DOCUMENTS_APP_URL}?token=${access_token}${subdomainUrl}`
          );
        }
      })
      .catch(() => {
        this.$router.back();
      });
  }
};
</script>
